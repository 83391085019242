/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react'
import { countBy } from 'ramda'
import { RebuildViewModelError } from './types'
import { useStyles } from './useStyles'

interface ErrorsProps {
  errors: Array<RebuildViewModelError>
}

export const Errors: FC<ErrorsProps> = ({ errors }) => {
  const classes = useStyles()
  if (errors.length === 0) {
    return null
  }
  const stats = countBy(
    (error: RebuildViewModelError) =>
      `${error.projection_name}-${error.error.message}`,
  )(errors)
  return (
    <div className={classes.container}>
      <div>
        <h4>Summary</h4>
        {JSON.stringify(stats, null, 2)}
      </div>
      {errors.map(({ id, projection_name, event, error }) => (
        <div key={id} className={classes.projectionError}>
          <span className={classes.projectionName}>
            Projection: {projection_name}
          </span>
          <pre className={classes.event}>
            {JSON.stringify(JSON.parse(event), null, 2)}
          </pre>
          <pre className={classes.errorStack}>{error.stack}</pre>
        </div>
      ))}
    </div>
  )
}

Errors.displayName = 'RebuildViewModels.Errors'
