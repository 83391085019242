import * as React from 'react'

function SvgCollapse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9.707 7.293a1 1 0 01.083 1.32l-.083.094-2.586 2.586a1 1 0 00-.083 1.32l.083.094 2.586 2.586a1 1 0 01-1.32 1.497l-.094-.083-2.586-2.586a2.999 2.999 0 01-.135-4.098l.135-.144 2.586-2.586a1 1 0 011.414 0zm8 0a1 1 0 01.083 1.32l-.083.094-2.586 2.586a1 1 0 00-.083 1.32l.083.094 2.586 2.586a1 1 0 01-1.32 1.497l-.094-.083-2.586-2.586a2.999 2.999 0 01-.135-4.098l.135-.144 2.586-2.586a1 1 0 011.414 0z'
      />
    </svg>
  )
}

export default SvgCollapse
