// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useState } from 'react'
import { RepairPathways } from './RepairPathways'
import { useRepairPathwaysMutation } from './types'
import { useNotifications } from '../../hooks/useNotifications'

export const RepairPathwaysContainer: FC = () => {
  const { notify, notifyError } = useNotifications()
  const [showStatus, setShowStatus] = useState(false)
  const [repairPathways] = useRepairPathwaysMutation()

  const onRepair = async (pathwayId?: string) => {
    try {
      const {
        data: {
          repairPathways: { pathway_count },
        },
      } = await repairPathways({
        variables: {
          input: {
            pathway_id: pathwayId,
          },
        },
      })
      setShowStatus(true)
      notify(`Dependency recompute started for ${pathway_count} pathways`)
    } catch (error) {
      notifyError({
        message: 'Something went wrong while recomputing pathway dependencies',
        error,
      })
    }
  }

  return <RepairPathways onRepair={onRepair} showStatus={showStatus} />
}

RepairPathwaysContainer.displayName = 'RepairPathwaysContainer'
