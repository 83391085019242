import React, { FC, useState } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { useRebuildViewModels } from '../../hooks/useRebuildViewModels'
import { RebuildViewModels } from './RebuildViewModels'
import {
  useRebuildGraphsMutation,
  useRefreshDefinitionReferencesMutation,
} from './types'

export const RebuildViewModelsContainer: FC = () => {
  const { notifyError, notify } = useNotifications()
  const { rebuildViewModels } = useRebuildViewModels()
  const [rebuildGraphs] = useRebuildGraphsMutation()
  const [refreshDefinitionReferences] = useRefreshDefinitionReferencesMutation()
  const [isBuilding, setBuilding] = useState(false)

  const onRebuildViewModels = async (pathway_id?: string) => {
    setBuilding(true)
    notify('Rebuilding started')
    await rebuildViewModels(pathway_id)
  }

  const onCompleteRebuildViewModels = async () => {
    setBuilding(false)
    notify('View models rebuild complete')
    await refreshDefinitionReferences()
  }

  const onRebuildGraphs = async (pathway_id?: string) => {
    try {
      setBuilding(true)
      notify('Rebuild graph started')
      await rebuildGraphs({
        variables: {
          input: {
            pathway_id,
          },
        },
      })
    } catch (error) {
      notifyError({ message: 'Error occurred while rebuilding graphs', error })
    }
  }

  return (
    <RebuildViewModels
      onRebuildViewModels={onRebuildViewModels}
      onRebuildGraphs={onRebuildGraphs}
      building={isBuilding}
      onComplete={onCompleteRebuildViewModels}
    />
  )
}

RebuildViewModelsContainer.displayName = 'RebuildViewModelsContainer'
