// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useState } from 'react'
import {
  PanelSectionHeader,
  PanelSection,
} from '@awell/ui-kit/components/PanelSection'
import { Button } from '@awell/ui-kit/components/Button'
import { StatusContainer } from './Status'
import { PathwaySelect } from '../PathwaySelect'
import { useStyles } from './useStyles'

interface RepairPathwaysProps {
  onRepair: (pathwayId?: string) => void
  showStatus: boolean
}

export const RepairPathways: FC<RepairPathwaysProps> = ({
  onRepair,
  showStatus,
}) => {
  const [pathwayId, setPathwayId] = useState(undefined)
  const classes = useStyles()
  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Repair pathways</h3>
      </PanelSectionHeader>
      <div>
        This maintenance operation does the following:
        <ul>
          <li>Recompute all dependencies</li>
          <li>Recompute pathway data point references</li>
        </ul>
        Pathway is optional, if you don&apos;t select a specific pathway all
        pathways will be repaired
      </div>
      <div className={classes.input}>
        <PathwaySelect
          value={pathwayId}
          onChange={setPathwayId}
          multiple={false}
        />
      </div>
      <Button variant='outlined' onClick={() => onRepair(pathwayId)}>
        Repair
      </Button>
      {showStatus && <StatusContainer />}
    </PanelSection>
  )
}

RepairPathways.displayName = 'RepairPathways'
