import React, { type FC } from 'react'
import { LinearProgress } from '@awell/ui-kit/components/LinearProgress'
import { TipBar } from '@awell/ui-kit/components/Tip'
import { useStyles } from './useStyles'
import type { ExportPathwayStatus } from '../../hooks/useExportPathway/types'
import { isNil } from 'lodash'

interface StatusProps {
  status: ExportPathwayStatus
}

export const Status: FC<StatusProps> = ({
  status: { message, url, content, count },
}) => {
  const classes = useStyles()
  return (
    <>
      <TipBar tipContent={message} />
      {!isNil(count) && (
        <div className={classes.status}>
          <div>{`${count.exported_count} / ${count.event_count}`}</div>
          <LinearProgress
            value={(count.exported_count / count.event_count) * 100}
          />
        </div>
      )}
      {!isNil(url) && (
        <div className={classes.download}>
          <a href={url} target='_blank' rel='noreferrer'>
            Download exported JSON file
          </a>
        </div>
      )}
      {/* When developing locally, we send the content (a large stringified JSON) over instead of uploading */}
      {/* The below lets you click to download the JSON in a file you can save locally and upload in another env */}
      {!isNil(content) && (
        <a
          href={`data:text/json;charset=utf-8,${encodeURIComponent(content)}`}
          download='exported-pathway.json'
        >
          Download exported JSON file
        </a>
      )}
    </>
  )
}

Status.displayName = 'ImportPathway.Status'
