export type Environments =
  | 'local'
  | 'development'
  | 'staging'
  | 'production'
  | 'production-us'
  | 'production-uk'
  | 'sandbox'

export const environmentShortName = (environment: Environments): string => {
  switch (environment) {
    case 'development':
      return 'Dev'
    case 'staging':
      return 'Staging'
    case 'production':
      return 'Prod EU'
    case 'production-us':
      return 'Prod US'
    case 'production-uk':
      return 'Prod UK'
    case 'sandbox':
      return 'sandbox'
    default:
      return environment
  }
}

interface Environment {
  environment: Environments
  version: string
  auth: {
    auth_cookies_allowed_domain: string
    stytch_public_token: string
    hostname: string
    dev_portal: {
      client_id: string
    }
    pathway_studio: {
      client_id: string
    }
    care: {
      client_id: string
    }
  }
  urls: {
    clinical_app: string
    dev_portal: string
    care: string
    pathway_studio: string
    ui_components_storybook: string
    design_api: string
    design_api_ws: string
    orchestration_api: string
    orchestration_api_ws: string
    firebase_functions: string
    calculations_api: string
    calculations_explorer: string
    careops: string
    hosted_pages: string
  }
  logging: {
    crash_reporter: {
      pathway_studio: string
      care: string
    }
  }
  intercom_app_id: string
  releases: {
    pathway_studio: string
    dev_portal: string
    care: string
  }
  cloudinary: {
    cloud_name: {
      pathway_studio: string
    }
    upload_preset: {
      pathway_studio: string
    }
  }
  statsig: {
    client_key: string
  }
}

export const environment: Environment = {
  environment: (process.env.AWELL_ENVIRONMENT as Environments) ?? 'production',

  version: process.env.AWELL_VERSION ?? '',
  auth: {
    auth_cookies_allowed_domain:
      process.env.AUTH_COOKIE_ALLOWED_DOMAIN ?? '.awellhealth.com',
    stytch_public_token: process.env.STYTCH_PUBLIC_TOKEN ?? '',
    hostname: process.env.AUTH_HOSTNAME ?? '',
    dev_portal: {
      client_id: process.env.AUTH_CLIENT_ID_DEV_PORTAL ?? '',
    },
    care: {
      client_id: process.env.AUTH_CLIENT_ID_CARE ?? '',
    },
    pathway_studio: {
      client_id: process.env.AUTH_CLIENT_ID_PATHWAY_STUDIO ?? '',
    },
  },
  urls: {
    clinical_app: process.env.URL_CLINICAL_APP ?? '',
    dev_portal: process.env.URL_DEV_PORTAL ?? '',
    care: process.env.URL_CARE ?? '',
    pathway_studio: process.env.URL_PATHWAY_STUDIO ?? '',
    ui_components_storybook: process.env.URL_UI_KIT_STORYBOOK ?? '',
    design_api: process.env.URL_PATHWAY_DESIGN_API ?? '',
    design_api_ws: process.env.URL_PATHWAY_DESIGN_API_WS ?? '',
    orchestration_api: process.env.URL_ORCHESTRATION_API ?? '',
    orchestration_api_ws: process.env.URL_ORCHESTRATION_API_WS ?? '',
    firebase_functions: process.env.URL_FIREBASE_FUNCTIONS ?? '',
    calculations_api: process.env.URL_CALCULATIONS_API ?? '',
    calculations_explorer: process.env.URL_CALCULATIONS_EXPLORER ?? '',
    careops: process.env.URL_CAREOPS ?? '',
    hosted_pages: process.env.URL_HOSTED_PAGES ?? '',
  },
  logging: {
    crash_reporter: {
      pathway_studio: process.env.PATHWAY_STUDIO_CRASH_REPORTER_URL ?? '',
      care: process.env.CARE_CRASH_REPORTER_URL ?? '',
    },
  },
  intercom_app_id: process.env.INTERCOM_APP_ID ?? '',
  releases: {
    pathway_studio: process.env.RELEASES_PATHWAY_STUDIO ?? '',
    dev_portal: process.env.RELEASES_DEV_PORTAL ?? '',
    care: process.env.RELEASES_CARE ?? '',
  },
  cloudinary: {
    cloud_name: {
      pathway_studio: process.env.CLOUDINARY_CLIENT_CLOUD_NAME_STUDIO ?? '',
    },
    upload_preset: {
      pathway_studio: process.env.CLOUDINARY_CLIENT_UPLOAD_PRESET_STUDIO ?? '',
    },
  },
  statsig: {
    client_key: process.env.STATSIG_CLIENT_KEY ?? '',
  },
}
