import { Panel } from '@awell/ui-kit/components/Panel'
import React, { FC } from 'react'
import { RebuildViewModelsContainer } from '../../components/RebuildViewModels'
import { useStyles } from './styles'

export const ViewModelsPage: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Panel>
        <RebuildViewModelsContainer />
      </Panel>
    </div>
  )
}

ViewModelsPage.displayName = 'ViewModelsPage'
