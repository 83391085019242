import React, { FC } from 'react'
import { LinearProgress } from '@awell/ui-kit/components/LinearProgress'
import { TipBar } from '@awell/ui-kit/components/Tip'
import { useStyles } from './useStyles'
import type { ImportPathwayStatus } from '../../hooks/useImportPathway/types'

interface StatusProps {
  status: ImportPathwayStatus
}

export const Status: FC<StatusProps> = ({ status: { message, count } }) => {
  const classes = useStyles()
  return (
    <>
      <TipBar tipContent={message} />
      {count && (
        <div className={classes.status}>
          <div>{`${count.imported_count} / ${count.event_count}`}</div>
          <LinearProgress
            value={(count.imported_count / count.event_count) * 100}
          />
          <div>Last event id: {count.event_id}</div>
        </div>
      )}
    </>
  )
}

Status.displayName = 'ImportPathway.Status'
