import { makeStyles } from '@material-ui/core'
import { spacing, colors, rounded } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  container: {
    ...spacing.padding.m,
  },
  summary: {
    display: 'flex',
  },
  info: {
    ...spacing.paddingX.m,
    flexGrow: 1,
  },
  type: {},
  timestamp: {
    ...spacing.paddingY.xxs,
    display: 'flex',
    color: colors.neutralDark600,
  },
  user: {
    ...spacing.paddingY.xxs,
    display: 'flex',
    color: colors.neutralDark600,
  },
  id: {},
  data: {
    ...rounded.s,
    ...spacing.paddingX.xxs,
    backgroundColor: colors.neutralLight40,
  },
  metadata: {},
  code: {
    ...spacing.paddingX.xxs,
    ...rounded.s,
    fontFamily: 'monospace',
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: colors.neutralLight40,
  },
})
