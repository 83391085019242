import { type Pathway, useListPathwaysQuery } from './types'
import { useNotifications } from '../useNotifications'

export const usePathways = (): {
  loading: boolean
  pathways: Array<Pathway>
} => {
  const { notifyError } = useNotifications()

  const { data, loading, error } = useListPathwaysQuery()

  notifyError({
    error,
    message: 'Something went wrong while loading pathways',
  })

  return {
    loading,
    pathways: data?.listPathways.pathways ?? [],
  }
}
