import { useNotifications } from '../useNotifications'
import { useShareFormsMutation } from './types'

export const useShareForms = () => {
  const [shareFormsMutation] = useShareFormsMutation()
  const { notifyError } = useNotifications()

  const shareForms = async (team_id: string, form_ids: Array<string>) => {
    try {
      await shareFormsMutation({
        variables: {
          input: {
            team_id,
            form_ids,
          },
        },
      })
    } catch (error) {
      notifyError({
        message: 'Something went wrong while sharing forms',
        error,
      })
    }
  }

  return {
    shareForms,
  }
}
