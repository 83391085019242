import { Button } from '@awell/ui-kit/components/Button'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import React, { FC } from 'react'
import { useRecomputeCalculationDataSources } from '../../hooks/useRecomputeCalculationDataSources'

export const RecomputeCalculationDataSources: FC = () => {
  const { recompute } = useRecomputeCalculationDataSources()

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Recompute calculation data sources</h3>
      </PanelSectionHeader>
      <Button variant='outlined' onClick={() => recompute()}>
        Recompute
      </Button>
    </PanelSection>
  )
}

RecomputeCalculationDataSources.displayName = 'RecomputeCalculationDataSources'
