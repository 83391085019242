import { type Tenant, useGetTenantsQuery } from './types'
import { useNotifications } from '../useNotifications'

export const useTenants = (): { loading: boolean; tenants: Array<Tenant> } => {
  const { notifyError } = useNotifications()

  const { data, loading, error } = useGetTenantsQuery()

  notifyError({
    error,
    message: 'Something went wrong while loading tenants',
  })

  const tenants = data?.tenants.tenants ?? []
  return {
    loading,
    tenants,
  }
}
