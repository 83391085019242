import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  panelHeaderBackButton: {
    position: 'absolute',
    top: 0,
    left: `-${spacing.xs}`,
  },
  panelHeaderCollapseButton: {
    transform: `translateX(${spacing.xxs})`,
  },
  panelHeaderContent: ({
    hasBackButton,
    hasStart,
  }: {
    hasBackButton: boolean
    hasStart: boolean
  }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: `calc(${spacing.icon} + ${spacing.xs})`,
    alignItems: 'center',
    marginLeft: hasBackButton && hasStart ? spacing.m : 0,
  }),
})

export const useStartStyles = makeStyles({
  contentStart: {
    marginRight: spacing.xs,
  },
})
export const useBodyStyles = makeStyles({
  contentBody: {
    flex: 1,
  },
})
