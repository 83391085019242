import {
  useClearViewModelsMutation,
  useRebuildViewModelsMutation,
} from './types'
import { useNotifications } from '../useNotifications'

interface RebuildViewModelsHook {
  rebuildViewModels: (pathway_id?: string) => Promise<void>
}

export const useRebuildViewModels = (): RebuildViewModelsHook => {
  const { notifyError, notify } = useNotifications()
  const [clearViewModelsMutation] = useClearViewModelsMutation()
  const [rebuildViewModelsMutation] = useRebuildViewModelsMutation()

  const rebuildViewModels = async (pathway_id?: string) => {
    try {
      if (pathway_id === undefined) {
        await clearViewModelsMutation()
      }
      await rebuildViewModelsMutation({
        variables: {
          input: {
            pathway_id,
          },
        },
      })
      notify('Rebuilding started')
    } catch (error) {
      notifyError({
        message: 'Error occurred while rebuilding view models',
        error,
      })
    }
  }

  return { rebuildViewModels }
}
