import { Panel } from '@awell/ui-kit/components/Panel'
import React, { type FC } from 'react'
import { ExportPathway } from '../../components/ExportPathway'
import { ImportPathway } from '../../components/ImportPathway'
import { useStyles } from './styles'

export const ExportImportPage: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Panel>
        <ExportPathway />
        <ImportPathway />
      </Panel>
    </div>
  )
}
