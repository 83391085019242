import { useEffect, useState } from 'react'
import {
  ImportPathwayStatus,
  useImportPathwayMutation,
  useOnImportPathwayStatusUpdateSubscription,
} from './types'
import { useNotifications } from '../useNotifications'

interface ImportPathwayHook {
  status?: ImportPathwayStatus
  importPathway: ({
    tenant_id,
    base64Json,
  }: {
    tenant_id: string
    base64Json: string
  }) => Promise<void>
}

export const useImportPathway = (): ImportPathwayHook => {
  const { notifyError, notify } = useNotifications()
  const [status, setStatus] = useState<ImportPathwayStatus | undefined>()
  const [importPathwayMutation] = useImportPathwayMutation()
  const { data: importStatus } = useOnImportPathwayStatusUpdateSubscription()

  useEffect(() => {
    if (importStatus) {
      setStatus(importStatus.onImportPathwayStatusUpdate)
    }
  }, [importStatus])

  const importPathway = async ({
    tenant_id,
    base64Json,
  }: {
    tenant_id: string
    base64Json: string
  }): Promise<void> => {
    try {
      const result = await importPathwayMutation({
        variables: {
          input: {
            tenant_id,
            base64Json,
          },
        },
      })
      if (result.data?.importPathway.success) {
        setStatus({ message: 'Import started' })
        notify('Import started')
      }
    } catch (error) {
      notifyError({ message: 'Error occurred while importing pathway', error })
    }
  }

  return { importPathway, status }
}
