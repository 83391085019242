import { Panel } from '@awell/ui-kit/components/Panel'
import React, { FC } from 'react'
import { ImportEventStream } from '../../components/ImportEventStream'
import { useStyles } from './styles'

export const ImportEventStreamPage: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Panel>
        <ImportEventStream />
      </Panel>
    </div>
  )
}

ImportEventStreamPage.displayName = 'ImportEventStreamPage'
