// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useEffect, useState } from 'react'
import { useOnEventReplayedSubscription } from './types'
import { useNotifications } from '../../../hooks/useNotifications'
import { Status } from './Status'

interface StatusContainerProps {
  onComplete: () => void
}

export const StatusContainer: FC<StatusContainerProps> = ({ onComplete }) => {
  const { notifyError } = useNotifications()
  const { data, error } = useOnEventReplayedSubscription()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (data) {
      setShow(true)
      const {
        eventReplayed: { event_count, replayed_count },
      } = data
      if (replayed_count === event_count) {
        onComplete()
      }
    }
  }, [data])
  if (error) {
    notifyError(error)
  }

  const eventCount = data?.eventReplayed?.event_count || 1
  const replayedCount = data?.eventReplayed?.replayed_count || 0
  const lastEventId = data?.eventReplayed?.event_id || ''
  return (
    show && (
      <Status
        eventCount={eventCount}
        replayedCount={replayedCount}
        lastEventId={lastEventId}
      />
    )
  )
}

StatusContainer.displayName = 'RebuildViewModel.StatusContainer'
