import React, { type FC } from 'react'
import {
  AppHeader as BaseAppHeader,
  useHeaderStyles,
} from '@awell/ui-kit/components/AppHeader'
import { Logo, LogoSizes } from '@awell/ui-kit/components/Logo'
import { Button } from '@awell/ui-kit/components/Button'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { Link } from '@reach/router'

export const AppHeader: FC = () => {
  const { appHeaderWithBorder } = useHeaderStyles()

  const handleLogout = (): void => {
    window.alert('Surprise !!! This is not implemented yet.')
  }

  return (
    <BaseAppHeader className={appHeaderWithBorder}>
      <Logo size={LogoSizes.small} />
      <Link to='/' style={{ marginLeft: spacing.m, color: 'inherit' }}>
        Developer Portal
      </Link>
      <div style={{ flexGrow: 1 }} />
      <Button variant='text' onClick={handleLogout}>
        Logout
      </Button>
    </BaseAppHeader>
  )
}

AppHeader.displayName = 'AppHeader'
