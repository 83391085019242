import { makeStyles } from '@material-ui/core'
import { spacing, type, rounded, colors } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  container: {
    marginTop: spacing.m,
    ...spacing.padding.m,
    ...rounded.m,
    backgroundColor: colors.neutralDark800,
    color: colors.neutralLight0,
  },
  projectionError: {
    fontSize: type.xs.fontSize,
  },
  projectionName: {},
  event: {},
  errorMessage: {},
  errorStack: {
    whiteSpace: 'pre-wrap',
  },
})
