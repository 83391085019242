import { makeStyles } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  input: {
    marginBottom: spacing.xs,
  },

  fileInput: {
    display: 'block',
    marginTop: spacing.xs,
  },
  status: {
    ...spacing.marginY.m,
  },
})
