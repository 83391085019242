// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable react/display-name */
import { IconButton, Button } from '@awell/ui-kit/components/Button'
import { Cross, Warning } from '@awell/ui-kit/components/Icons'
import {
  NotificationMessage,
  NotificationMessageContent,
  NotificationMessageContentActions,
} from '@awell/ui-kit/components/NotificationMessage'
import { SnackbarContent, useSnackbar } from 'notistack'
import React from 'react'

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = key => (
    <IconButton onClick={() => closeSnackbar(key)}>
      <Cross />
    </IconButton>
  )

  /**
   * Use this to notify the user about an error using a snackbar notification.
   * The error parameter is optional to make it easier to use this to report
   * errors originating from graphql queries: query hooks always return an error
   * property, so this saves from writing an `if(error !== undefined)` clause
   * for each query.
   */
  const notifyError = ({
    message,
    error,
  }: {
    message: string
    error?: unknown
  }) => {
    if (error !== undefined) {
      enqueueSnackbar(message, {
        persist: true,
        action,
        content: key => (
          <SnackbarContent style={{ maxWidth: 400 }}>
            <NotificationMessage
              icon={<Warning />}
              message={message}
              onClose={() => closeSnackbar(key)}
            >
              <>
                <NotificationMessageContent>
                  Uh oh, something went wrong there... Error message:
                  {error.message}
                </NotificationMessageContent>
                <NotificationMessageContentActions>
                  <Button variant='text' onClick={() => console.error(error)}>
                    Log error in console
                  </Button>
                </NotificationMessageContentActions>
              </>
            </NotificationMessage>
          </SnackbarContent>
        ),
      })
    }
  }

  const notify = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'info',
    })
  }

  return { notifyError, notify }
}
