// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import { InputField } from '@awell/ui-kit/components/InputField'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import React, { FC, useState } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { PathwaySelect } from '../PathwaySelect'
import { CustomActionInput } from './types'
import { useStyles } from './useStyles'

interface AddCustomActionToPathwayProps {
  onAddCustomActionToPathway: ({
    pathwayIds,
    customAction,
  }: {
    pathwayIds: Array<string>
    customAction: CustomActionInput
  }) => void
}

export const AddCustomActionToPathway: FC<AddCustomActionToPathwayProps> = ({
  onAddCustomActionToPathway,
}) => {
  const { notifyError } = useNotifications()
  const [pathwayIds, setPathwayIds] = useState([])
  const [customActionString, setCustomActionString] = useState('')
  const classes = useStyles()

  const handleBtnClick = (textInput: string) => {
    try {
      onAddCustomActionToPathway({
        pathwayIds,
        customAction: JSON.parse(textInput),
      })
    } catch (error) {
      notifyError({
        error,
        message:
          'Something went wrong while parsing the input string, please check the input',
      })
    }
  }

  const example = {
    name: 'EMR Integration',
    type: 'PUSH_TO_EMR',
    category: 'Integration',
    fields: [
      {
        name: 'fallback_physician_id',
        label: 'Fallback physician (hospital id)',
        type: 'TEXT',
        description:
          'If patient has no primary physician the report will be attached to this physician',
      },
      {
        name: 'script_id',
        label: 'RPA Script ID',
        type: 'TEXT',
        readonly: true,
        value: 'orthopedics_push_message_to_hix',
        mandatory: true,
      },
      {
        name: 'type',
        label: 'Type',
        type: 'SELECT',
        options: [{ label: 'Zorgpad', value: 'Zorgpad' }],
        description: 'Type of contact that will be created in HiX',
        mandatory: true,
      },
      {
        name: 'location',
        label: 'Location',
        type: 'SELECT',
        options: [{ label: 'Rumbeke', value: 'Rumbeke' }],
        description:
          'The hospital’s location or campus where the report will be attached to.',
        mandatory: true,
      },
    ],
  }
  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Add custom action to pathway(s)</h3>
      </PanelSectionHeader>
      <div className={classes.input}>
        <PathwaySelect value={pathwayIds} onChange={setPathwayIds} multiple />
      </div>
      <div>
        This maintenance operation does the following:
        <ul>
          <li>Adds the supplied custom action to the pathway(s)</li>
          <li>Please input a custom action object in the input field</li>
          <li>
            For e.g.
            <br />
            <pre>{JSON.stringify(example, null, 2)}</pre>
          </li>
        </ul>
      </div>
      <div className={classes.input}>
        <InputField
          type='text'
          rows={30}
          multiline
          label='Custom Action'
          value={customActionString}
          onChange={setCustomActionString}
        />
      </div>
      <Button
        onClick={() => handleBtnClick(customActionString)}
        disabled={!customActionString}
      >
        Add Custom Action To Pathways
      </Button>
    </PanelSection>
  )
}
