import { useEffect, useState } from 'react'
import {
  ExportPathwayStatus,
  useExportPathwayMutation,
  useOnExportPathwayStatusUpdateSubscription,
} from './types'
import { useNotifications } from '../useNotifications'

interface ExportPathwayHook {
  exportPathway: (pathway_id: string) => Promise<void>
  status?: ExportPathwayStatus
}

export const useExportPathway = (): ExportPathwayHook => {
  const { notifyError, notify } = useNotifications()
  const [status, setStatus] = useState<ExportPathwayStatus | undefined>()
  const [exportPathwayMutation] = useExportPathwayMutation()
  const { data: exportStatus } = useOnExportPathwayStatusUpdateSubscription()

  useEffect(() => {
    if (exportStatus) {
      setStatus(exportStatus.onExportPathwayStatusUpdate)
    }
  }, [exportStatus])

  const exportPathway = async (pathway_id: string) => {
    try {
      const result = await exportPathwayMutation({
        variables: {
          input: {
            pathway_id,
          },
        },
      })
      if (result.data?.exportPathway.success) {
        setStatus({ message: 'Export started' })
        notify('Export started')
      }
    } catch (error) {
      notifyError({ message: 'Error occurred while exporting pathway', error })
    }
  }

  return { exportPathway, status }
}
