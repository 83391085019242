import React, { FC } from 'react'
import { useNotifications } from '../../../hooks/useNotifications'
import { useOnPathwayRepairedSubscription } from './types'
import { Status } from './Status'

export const StatusContainer: FC = () => {
  const { notifyError } = useNotifications()
  const { data, error } = useOnPathwayRepairedSubscription()

  if (error) {
    notifyError(error)
  }

  const pathwayCount = data?.pathwayRepaired?.pathway_count || 1
  const repairedCount = data?.pathwayRepaired?.repaired_count || 0

  return <Status pathwayCount={pathwayCount} repairedCount={repairedCount} />
}

StatusContainer.displayName = 'RecomputePathwayDependencies.StatusContainer'
