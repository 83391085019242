import { Button } from '@awell/ui-kit/components/Button'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core'
import React, { FC, useState } from 'react'
import {
  ImportState,
  useImportEventStream,
} from '../../hooks/useImportEventStream'
import { PathwaySelect } from '../PathwaySelect'
import { StatusContainer } from '../RebuildViewModels/Status'

export const ImportEventStream: FC = () => {
  const { importState, rebuildViewModels, shareEverything } =
    useImportEventStream()
  const [activeStep, setActiveStep] = useState(0)
  const [pathwaysToShare, setPathwaysToShare] = useState<Array<string>>([])

  const goToNextStep = () => setActiveStep(activeStep + 1)

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Share pathways</h3>
      </PanelSectionHeader>
      <Stepper activeStep={activeStep} orientation='vertical'>
        <Step>
          <StepLabel>Rebuild view models</StepLabel>
          <StepContent>
            <StatusContainer onComplete={() => null} />
            <Button
              onClick={rebuildViewModels}
              disabled={importState === ImportState.REBUILDING_VIEW_MODELS}
            >
              Start
            </Button>
            <Button variant='outlined' onClick={goToNextStep}>
              Next
            </Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Share all forms & pathways</StepLabel>
          <StepContent>
            <PathwaySelect
              multiple
              value={pathwaysToShare}
              onChange={setPathwaysToShare}
            />
            <Button
              onClick={() => shareEverything({ pathwayIds: pathwaysToShare })}
              disabled={importState === ImportState.SHARING_PATHWAYS_AND_FORMS}
            >
              Share it
            </Button>
          </StepContent>
        </Step>
      </Stepper>
    </PanelSection>
  )
}

ImportEventStream.displayName = 'ImportEventStream'
