import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import classnames from 'classnames'
import { spacing, colors } from '../../utils/style-guide'
import { lineClamp } from '../../utils/line-clamp'

interface TipBarProps {
  tipContent: string
  absolute?: boolean
}

const useStyles = makeStyles({
  tipBarContainer: {
    backgroundColor: colors.neutralLight20,
    padding: `${spacing.xxs} ${spacing.s}`,
    '&> span': {
      ...lineClamp(3),
      margin: '0 auto',
    },
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: '100%',
  },
})

export const TipBar: FC<TipBarProps> = ({ tipContent, absolute = false }) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(
        classes.tipBarContainer,
        absolute && classes.absolute,
      )}
    >
      <span>{tipContent}</span>
    </div>
  )
}

TipBar.displayName = 'TipBar'
