import React, { FC } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  makeStyles,
} from '@material-ui/core'

import { fonts, type } from '../../utils/style-guide'

interface ImageCardProps {
  imageSrc: string
}

const useStyles = makeStyles({
  media: {
    height: '10rem',
  },
  content: {
    fontSize: type.m.fontSize,
  },
})

export const ImageCard: FC<ImageCardProps> = ({ imageSrc, children }) => {
  const classes = useStyles()
  return (
    <Card>
      <CardActionArea>
        <CardMedia className={classes.media} image={imageSrc} />
        <CardContent className={classes.content}>{children}</CardContent>
      </CardActionArea>
    </Card>
  )
}
