import React, { FC } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { useAddCustomFieldToPathwayMutation, Label } from './types'
import { AddCustomFieldToPathway } from './AddCustomFieldToPathway'

export const AddCustomFieldToPathwayContainer: FC = () => {
  const { notifyError, notify } = useNotifications()
  const [addCustomFieldToPathway] = useAddCustomFieldToPathwayMutation()

  const onAddCustomFieldToPathways = async ({
    pathwayIds,
    labels,
  }: {
    pathwayIds: Array<string>
    labels: Array<Label>
  }) => {
    try {
      await Promise.all(
        pathwayIds.map(async pathway_id => {
          await addCustomFieldToPathway({
            variables: {
              input: {
                pathway_id,
                labels,
              },
            },
          })
        }),
      )
      notify('Successfully added custom field to pathway')
    } catch (error) {
      notifyError({
        error,
        message: 'Something went wrong while adding custom field to pathway',
      })
    }
  }

  return (
    <AddCustomFieldToPathway
      onAddCustomFieldToPathways={onAddCustomFieldToPathways}
    />
  )
}
