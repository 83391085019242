import { useListFormsQuery } from '../../generated/types-design'
import { useNotifications } from '../useNotifications'

export const useForms = () => {
  const { notifyError } = useNotifications()

  const { data, loading, error } = useListFormsQuery()

  notifyError({
    error,
    message: 'Something went wrong while loading forms',
  })

  return {
    loading,
    forms: data?.listForms.forms,
  }
}
