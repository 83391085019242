import { environment } from '@awell/libs-web/environment'
import { makeStyles } from '@material-ui/core'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import * as stytch from 'stytch'
import GoogleIconSvg from './GoogleIconSvg'

export const formatOAuthStartURL = (
  redirectDomain: string,
  orgSlug: string,
  publicToken: string,
): string => {
  const redirectURL = redirectDomain + '/authenticate'
  return `${process.env.STYTCH_PROJECT_ENV === 'live' ? stytch.envs.live : stytch.envs.test}v1/b2b/public/oauth/google/start?public_token=${publicToken}&slug=${orgSlug}&login_redirect_url=${redirectURL}`
}

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 12px',
    marginTop: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    textDecoration: 'none',
    color: '#333',
    backgroundColor: '#f8f8f8',
    transition: 'background-color 0.2s',
    width: '200px',
    '&:hover': {
      backgroundColor: '#e8e8e8',
    },
  },
  icon: {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  },
  text: {
    verticalAlign: 'middle',
  },
})

export const OAuthButton = (): JSX.Element => {
  const classes = useStyles()
  const [oAuthStartUrl, setOAuthStartUrl] = useState<string>('')
  const { protocol, host } = window.location

  useEffect(() => {
    setOAuthStartUrl(
      formatOAuthStartURL(
        `${protocol}//${host}`,
        process.env.STYTCH_DEV_ORG_SLUG ?? 'awell-dev',
        environment.auth.stytch_public_token,
      ),
    )
  }, [])

  return (
    <Link href={oAuthStartUrl}>
      <a className={classes.button}>
        <div className={classes.icon}>
          <GoogleIconSvg />
        </div>
        <span className={classes.text}>Login with Google</span>
      </a>
    </Link>
  )
}
