import React, { FC } from 'react'
import { LinearProgress } from '@awell/ui-kit/components/LinearProgress'
import { useStyles } from './useStyles'

interface StatusProps {
  pathwayCount: number
  repairedCount: number
}

export const Status: FC<StatusProps> = ({ pathwayCount, repairedCount }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.label}>
        {`${repairedCount} / ${pathwayCount}`}
      </div>
      <LinearProgress value={(repairedCount / pathwayCount) * 100} />
    </div>
  )
}

Status.displayName = 'RecomputePathwayDependencies.Status'
