import React, { FC } from 'react'
import {
  Box,
  Typography,
  LinearProgress as MdLinearProgress,
} from '@material-ui/core'

interface LinearProgressProps {
  value: number
}

export const LinearProgress: FC<LinearProgressProps> = ({ value }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <MdLinearProgress variant='determinate' value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

LinearProgress.displayName = 'LinearProgress'
