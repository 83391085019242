import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'
import { IconWrapper } from '../IconWrapper'

interface Props {
  icon?: JSX.Element
}

const useStyles = makeStyles({
  panelSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing.xs,
  },
  icon: {
    marginRight: spacing.xs,
  },
})

export const PanelSectionHeader: React.FC<Props> = ({ icon, children }) => {
  const classes = useStyles()

  return (
    <header className={classes.panelSectionHeader}>
      {icon && <IconWrapper className={classes.icon}>{icon}</IconWrapper>}

      {children}
    </header>
  )
}

PanelSectionHeader.displayName = 'PanelSectionHeader'
