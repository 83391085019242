import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Flex } from '../Flex'
import { IconWrapper } from '../IconWrapper'

interface ConnectPanelSectionsIconProps {
  icon: JSX.Element
}

const useStyles = makeStyles({
  iconWrapper: {
    height: 'auto',
  },
})

export const ConnectPanelSectionsIcon: FC<ConnectPanelSectionsIconProps> = ({
  icon,
}) => {
  const classes = useStyles()
  return (
    <Flex className={classes.iconWrapper} justify='center'>
      <IconWrapper>{icon}</IconWrapper>
    </Flex>
  )
}
