// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useState, useEffect } from 'react'
import { useOnProjectionErrorSubscription } from './types'
import { useNotifications } from '../../../hooks/useNotifications'
import { Errors } from './Errors'

export const ErrorsContainer: FC = () => {
  const { notifyError } = useNotifications()
  const { data, error } = useOnProjectionErrorSubscription()
  const [errors, setErrors] = useState([])
  useEffect(() => {
    if (data) {
      const { projectionError } = data
      setErrors([projectionError, ...errors])
    }
  }, [data])
  if (error) {
    notifyError(error)
  }
  return <Errors errors={errors} />
}

Errors.displayName = 'RebuildViewModelsErrorsContainer'
