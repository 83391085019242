// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import React, { type FC, useState } from 'react'
import { AlertModal } from '@awell/ui-kit/components/AlertModal'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { PathwaySelect } from '../PathwaySelect'
import { ErrorsContainer } from './Errors'
import { StatusContainer } from './Status'

interface RebuildViewModelsProps {
  onRebuildViewModels: (pathway_id?: string) => void
  onRebuildGraphs: (pathway_id?: string) => void
  building: boolean
  onComplete: () => void
}

export const RebuildViewModels: FC<RebuildViewModelsProps> = ({
  onRebuildViewModels,
  onRebuildGraphs,
  building,
  onComplete,
}) => {
  const [pathwayId, setPathwayId] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [modalAction, setModalAction] = useState<null | (() => void)>(null)

  const openAlertModalForThisAction = (action: () => void) => {
    setOpen(true)
    setModalAction(action)
  }

  const resetModal = () => {
    setOpen(false)
    setModalAction(null)
  }
  const handleRebuildViewModels = () => {
    onRebuildViewModels(pathwayId)
    resetModal()
  }

  const handleRebuildGraph = () => {
    onRebuildGraphs(pathwayId)
    resetModal()
  }

  const alertModalLabels = {
    heading: 'Rebuild?',
    primaryAction: 'Proceed',
    secondaryAction: 'Cancel',
  }

  return (
    <>
      <PanelSection paddingTop paddingBottom>
        <PanelSectionHeader>
          <h3>Rebuild stuff</h3>
        </PanelSectionHeader>
        <PathwaySelect
          value={pathwayId}
          onChange={setPathwayId}
          multiple={false}
        />
        <Button
          onClick={() => {
            openAlertModalForThisAction(() => handleRebuildViewModels)
          }}
          disabled
        >
          Rebuild view models
        </Button>
        <Button
          onClick={() => {
            openAlertModalForThisAction(() => handleRebuildGraph)
          }}
          disabled={building}
        >
          Rebuild graphs
        </Button>
        <StatusContainer onComplete={onComplete} />
        <ErrorsContainer />
      </PanelSection>
      <AlertModal
        labels={alertModalLabels}
        open={open}
        onAction={() => {
          modalAction()
        }}
        onClose={() => {
          setOpen(!open)
        }}
        appearance='danger'
      >
        <Type variant={TypeVariants.regular}>
          Rebuilding view models or graphs should be validated with at least
          another developer in the team. If you&apos;re unsure, please check
          within the Dev channel before triggering this rebuild. If you know
          what you&apos;re doing and are happy to proceed, select Proceed below.
        </Type>
      </AlertModal>
    </>
  )
}

RebuildViewModels.displayName = 'RebuildViewModels'
