// @ts-nocheck TODO: remove this comment and fix errors
import '@awell/ui-kit/components/index.css'
import { AppLayout } from '@awell/ui-kit/layouts/AppLayout'
import { type RouteComponentProps } from '@reach/router'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { AppHeader } from './components/AppHeader'
import { GraphqlWrapper } from './GraphqlWrapper'
import { NotificationProvider } from './NotificationProvider'
import { Routes } from './routes'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b'
import { environment } from '@awell/libs-web/environment'

const useStyles = makeStyles({
  body: {
    backgroundColor: colors.neutralLight40,
  },
})

export const App: React.FC<RouteComponentProps> = () => {
  const stytch = new StytchB2BUIClient(environment.auth.stytch_public_token, {
    cookieOptions: {
      opaqueTokenCookieName: `stytch_session`,
      jwtCookieName: `stytch_session_jwt`,
      domain: environment.auth.auth_cookies_allowed_domain,
      availableToSubdomains: true,
    },
  })

  const classes = useStyles()
  return (
    <StytchB2BProvider stytch={stytch}>
      <NotificationProvider>
        <GraphqlWrapper>
          <AppLayout>
            <AppLayout.Header>
              <AppHeader />
            </AppLayout.Header>
            <AppLayout.Body>
              <ScrollContainer className={classes.body}>
                <Routes />
              </ScrollContainer>
            </AppLayout.Body>
          </AppLayout>
        </GraphqlWrapper>
      </NotificationProvider>
    </StytchB2BProvider>
  )
}
App.displayName = 'App'
