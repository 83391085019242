import React, { useState, type FC } from 'react'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import { Button } from '@awell/ui-kit/components/Button'
import { PathwaySelect } from '../PathwaySelect'
import { useStyles } from './useStyles'
import { useNotifications } from '../../hooks/useNotifications'
import { useExportPathway } from '../../hooks/useExportPathway'
import { Status } from './Status'
import { isNil } from 'lodash'

export const ExportPathway: FC = () => {
  const { notifyError } = useNotifications()
  const { exportPathway, status } = useExportPathway()
  const [pathwayId, setPathwayId] = useState('')
  const classes = useStyles()

  const handleExport = async (): Promise<void> => {
    try {
      await exportPathway(pathwayId)
    } catch (error) {
      notifyError({
        error,
        message: 'Something went wrong while exporting pathway',
      })
    }
  }

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Export pathway</h3>
      </PanelSectionHeader>
      <div className={classes.input}>
        <PathwaySelect
          value={pathwayId}
          onChange={setPathwayId}
          multiple={false}
        />
      </div>
      <Button
        variant='outlined'
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleExport}
        disabled={isNil(pathwayId)}
        className={classes.input}
      >
        Export
      </Button>

      {status != null && <Status status={status} />}
    </PanelSection>
  )
}
