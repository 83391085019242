import { environment } from '@awell/libs-web/environment'
import { ImageCard } from '@awell/ui-kit/components/ImageCard'
import { GridLayout } from '@awell/ui-kit/layouts/GridLayout'
import { Link, RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import garage from '../assets/garage.jpg'
import scaffolding from '../assets/scaffolding.jpg'
import telescope from '../assets/telescope.jpg'
import waterfall from '../assets/waterfall.jpg'
import stopwatch from '../assets/stopwatch.jpg'
import exportimport from '../assets/exportimport.jpg'

export const Home: FC<RouteComponentProps> = () => {
  return (
    <div style={{ maxWidth: 1200, margin: '0 auto' }}>
      <GridLayout>
        <Link to='/events'>
          <ImageCard imageSrc={stopwatch}>Dive into the event stream</ImageCard>
        </Link>
        <Link to='/view-models'>
          <ImageCard imageSrc={telescope}>Work on the view models</ImageCard>
        </Link>
        <Link to='/maintenance'>
          <ImageCard imageSrc={garage}>
            Get your tools out, it&apos;s maintenance time !
          </ImageCard>
        </Link>
        <Link to='/share-pathways'>
          <ImageCard imageSrc={waterfall}>Share pathways</ImageCard>
        </Link>
        <a
          href={environment.urls.ui_components_storybook}
          target='_blank'
          rel='noopener noreferrer'
        >
          <ImageCard imageSrc={scaffolding}>Explore the ui-kit</ImageCard>
        </a>
        <Link to='/export-import'>
          <ImageCard imageSrc={exportimport}>Export/Import pathways</ImageCard>
        </Link>
      </GridLayout>
    </div>
  )
}

Home.displayName = 'Home'
