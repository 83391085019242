import * as React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

const useStyles = makeStyles({
  panelSection: {
    height: 'auto',
  },
  panelSectionDivided: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.neutralLight50,
  },
  marginBottom: {
    marginBottom: spacing.xxxl,
  },
  paddingTop: {
    paddingTop: spacing.s,
  },
  paddingTopSmall: {
    paddingTop: spacing.xs,
  },
  paddingTopXSmall: {
    paddingTop: spacing.xxs,
  },
  paddingBottom: {
    paddingBottom: spacing.s,
  },
  paddingBottomSmall: {
    paddingBottom: spacing.xs,
  },
  paddingBottomXSmall: {
    paddingBottom: spacing.xxs,
  },
  paddingHorizontal: {
    ...spacing.paddingX.xs,
  },
  paddingHorizontalSmall: {
    ...spacing.paddingX.xxs,
  },
  paddingVertical: {
    ...spacing.paddingY.xs,
  },
  paddingVerticalSmall: {
    ...spacing.paddingY.xxs,
  },
  fill: {
    flex: 1,
  },
  contentWrapper: {
    backgroundColor: (props: PanelSectionProps) => props.backgroundColor,
    ...spacing.padding.xxs,
  },
})

interface PanelSectionProps {
  divided?: boolean
  marginBottom?: boolean
  paddingTop?: boolean | 'small' | 'x-small'
  paddingBottom?: boolean | 'small' | 'x-small'
  paddingHorizontal?: boolean | 'small'
  paddingVertical?: boolean | 'small'
  fill?: boolean
  backgroundColor?: string
  tag?: keyof JSX.IntrinsicElements
}

export const PanelSection: React.FC<PanelSectionProps> = ({
  children,
  divided = false,
  paddingTop = false,
  paddingBottom = false,
  marginBottom = false,
  paddingHorizontal = true,
  paddingVertical = false,
  backgroundColor = 'transparent',
  fill = false,
  tag = 'div',
}) => {
  const classes = useStyles({ backgroundColor })
  const Tag = tag

  return (
    <Tag
      className={classnames({
        [classes.panelSection]: true,
        [classes.marginBottom]: marginBottom,
        [classes.paddingTop]: paddingTop === true,
        [classes.paddingTopXSmall]: paddingTop === 'x-small',
        [classes.paddingTopSmall]: paddingTop === 'small',
        [classes.paddingBottom]: paddingBottom,
        [classes.paddingBottomXSmall]: paddingBottom === 'x-small',
        [classes.paddingBottomSmall]: paddingBottom === 'small',
        [classes.paddingHorizontal]: paddingHorizontal,
        [classes.paddingHorizontalSmall]: paddingHorizontal === 'small',
        [classes.paddingVertical]: paddingVertical,
        [classes.paddingVerticalSmall]: paddingVertical === 'small',
        [classes.panelSectionDivided]: divided,
        [classes.fill]: fill,
      })}
    >
      <div className={classes.contentWrapper}>{children}</div>
    </Tag>
  )
}
PanelSection.displayName = 'PanelSection'
