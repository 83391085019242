import { useNotifications } from '../useNotifications'
import { useSharePathwaysMutation } from './types'

export const useSharePathways = () => {
  const [sharePathwaysMutation] = useSharePathwaysMutation()
  const { notifyError } = useNotifications()

  const sharePathways = async (team_id: string, pathway_ids: Array<string>) => {
    try {
      await sharePathwaysMutation({
        variables: {
          input: {
            team_id,
            pathway_ids,
          },
        },
      })
    } catch (error) {
      notifyError({
        message: 'Something went wrong while sharing pathways',
        error,
      })
    }
  }

  return {
    sharePathways,
  }
}
