import { Panel } from '@awell/ui-kit/components/Panel'
import React, { FC } from 'react'
import { AddCustomFieldToPathwayContainer } from '../../components/AddCustomFieldToPathway'
import { AddCustomActionToPathwayContainer } from '../../components/AddCustomActionToPathway'
import { RecomputeCalculationDataSources } from '../../components/RecomputeCalculationDataSources'
import { RepairPathwaysContainer } from '../../components/RepairPathways'
import { ResetPatientProfileDataPointDefinitions } from '../../components/ResetPatientProfileDataPointDefinitions'
import { TransferPathwayContainer } from '../../components/TransferPathway'
import { useStyles } from './styles'

export const MaintenancePage: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Panel>
        <RecomputeCalculationDataSources />
        <TransferPathwayContainer />
        <RepairPathwaysContainer />
        <AddCustomFieldToPathwayContainer />
        <ResetPatientProfileDataPointDefinitions />
        <AddCustomActionToPathwayContainer />
      </Panel>
    </div>
  )
}

MaintenancePage.displayName = 'MaintenancePage'
