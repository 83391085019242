import React, { type FC } from 'react'
import { Select, Option } from '@awell/ui-kit/components/Select'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { useTenants } from '../../hooks/useTenants'

interface TenantSelectProps {
  value: string
  onChange: (pathwayId: string) => void
}

export const TenantSelect: FC<TenantSelectProps> = ({ value, onChange }) => {
  const { loading, tenants } = useTenants()
  if (loading) {
    return <Spinner />
  }
  return (
    <Select label='Tenant' value={value} onChange={onChange}>
      {tenants.map(({ id, name }) => (
        <Option key={id} value={id} label={`${name} (${id})`}>
          {name} ({id})
        </Option>
      ))}
    </Select>
  )
}
