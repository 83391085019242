// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { useEventsQuery } from './types'
import { EventList } from './EventList'
import { useNotifications } from '../../hooks/useNotifications'

export const EventListContainer: FC = () => {
  const { data, loading, error, refetch } = useEventsQuery({
    variables: {
      input: {},
    },
  })
  const { notifyError } = useNotifications()

  if (loading) {
    return <Spinner />
  }

  if (error) {
    notifyError(error)
    return null
  }

  const { events } = data.events

  const onSearch = ({
    stream,
    eventType,
    userId,
    sagaId,
  }: {
    stream: string
    eventType: string
    userId: string
    sagaId: string
  }) =>
    refetch({
      input: {
        stream: stream || undefined,
        event_type: eventType || undefined,
        user_id: userId || undefined,
        saga_id: sagaId || undefined,
      },
    })

  return <EventList events={events} onSearch={onSearch} />
}

EventListContainer.displayName = 'EventListContainer'
