import React, { FC, Children } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { spacing } from '../../utils/style-guide'

const useStyles = makeStyles({
  container: {
    margin: spacing.l,
  },
})

export const GridLayout: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        {Children.map(children, child => (
          <Grid item xs={4}>
            {child}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
