import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, Router, navigate } from '@reach/router'
import { AppTheme } from '@awell/ui-kit/components/AppTheme'
import { Flex } from '@awell/ui-kit/components/Flex'
import { Home } from './Home'
import { Events } from './Events'
import { Maintenance } from './Maintenance'
import { ViewModels } from './ViewModels'
import { ImportEventStream } from './ImportEventStream'
import { ExportImport } from './ExportImport'
import { OAuthButton } from '../components/OAuthButton'
import { isNil } from 'lodash'
import { useStytchMemberSession } from '@stytch/react/b2b'
import { Authenticate } from './Authenticate'

interface ProtectedRouteProps extends RouteComponentProps {
  component: React.FC<RouteComponentProps>
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const { session } = useStytchMemberSession()
  const allowed_organization_id =
    process.env.STYTCH_ORGANIZATION_ID ??
    'organization-test-9dc35114-e414-4f27-8530-58eff7ed042c'

  useEffect(() => {
    if (isNil(session) || session.organization_id !== allowed_organization_id) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }, [session])

  if (!isAuthenticated) {
    return (
      <Flex justify='center' align='center'>
        <OAuthButton />
      </Flex>
    )
  }

  return <Component {...rest} />
}

export const Routes: FC = () => {
  return (
    <AppTheme seed='portal'>
      <Router style={{ height: '100%' }}>
        <ProtectedRoute path='/' default component={Home} />
        <ProtectedRoute path='/events' component={Events} />
        <ProtectedRoute path='/share-pathways' component={ImportEventStream} />
        <ProtectedRoute path='/maintenance' component={Maintenance} />
        <ProtectedRoute path='/view-models' component={ViewModels} />
        <ProtectedRoute path='/export-import' component={ExportImport} />
        <Authenticate path='/authenticate' />
      </Router>
    </AppTheme>
  )
}
