import classnames from 'classnames'
import React, { CSSProperties, useRef } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  panel: {
    position: 'relative',
    height: '100%',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'initial',
    },
  },
  content: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

// TODO: those props should be most likely removed - I didn't find any place they are actually used
interface PanelProps {
  style?: CSSProperties
  className?: string
  collapsed?: boolean
  dataCy?: string
}

export const Panel: React.FC<PanelProps> = ({
  children,
  style,
  className,
  collapsed = false,
  dataCy = null,
}) => {
  const panelRef = useRef(null)
  const classes = useStyles()

  return !collapsed ? (
    <aside
      ref={panelRef}
      style={style}
      className={classnames(
        {
          [classes.panel]: true,
        },
        className,
      )}
      data-cy={dataCy}
    >
      <div className={classnames(classes.content)}>{children}</div>
    </aside>
  ) : null
}

Panel.displayName = 'Panel'
