import { ApolloProvider } from '@apollo/client'
import { environment } from '@awell/libs-web/environment'
import { createClient } from '@awell/libs-web/graphql'
import React, { FC } from 'react'
import fragmentTypes from './generated/fragment-types'

export const GraphqlWrapper: FC = ({ children }) => {
  const client = createClient({
    httpUri: environment.urls.design_api,
    wsUri: environment.urls.design_api_ws,
    cacheConfig: {
      possibleTypes: fragmentTypes.possibleTypes,
    },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
