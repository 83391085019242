import React, { type FC } from 'react'
import { Select, Option } from '@awell/ui-kit/components/Select'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { usePathways } from '../../hooks/usePathways'
import { isNil } from 'lodash'

interface PathwaySelectProps {
  multiple: boolean
}

interface MultiplePathwaySelectProps extends PathwaySelectProps {
  multiple: true
  value: Array<string>
  onChange: (pathwayIds: Array<string>) => void
}

interface SinglePathwaySelectProps extends PathwaySelectProps {
  multiple: false
  value: string
  onChange: (pathwayId: string) => void
}

export const PathwaySelect: FC<
  SinglePathwaySelectProps | MultiplePathwaySelectProps
> = ({ multiple, value, onChange }) => {
  const { loading, pathways } = usePathways()
  if (loading || isNil(pathways)) {
    return <Spinner />
  }
  return (
    // @ts-expect-error: TODO: figure out why ts complains about multiple prop
    <Select
      label='Pathway'
      value={value}
      onChange={onChange}
      multiple={multiple}
    >
      {pathways.map(({ id, title }) => (
        <Option key={id} value={id} label={`${title} (${id})`}>
          {title} ({id})
        </Option>
      ))}
    </Select>
  )
}

PathwaySelect.displayName = 'PathwaySelect'
