import React, { type FC, useEffect, useState } from 'react'
import { type RouteComponentProps, navigate } from '@reach/router'
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b'
import { environment } from '@awell/libs-web/environment'
import { Spinner } from '@awell/ui-kit/components/Spinner'

const useQuery = () => {
  return new URLSearchParams(window.location.search)
}

const stytch = new StytchB2BUIClient(environment.auth.stytch_public_token, {
  cookieOptions: {
    opaqueTokenCookieName: `stytch_session`,
    jwtCookieName: `stytch_session_jwt`,
    domain: environment.auth.auth_cookies_allowed_domain,
    availableToSubdomains: true,
  },
})

export const Authenticate: FC<RouteComponentProps> = () => {
  const query = useQuery()
  const token = query.get('token')

  const authenticate = (oauth_token: string) => {
    stytch.oauth
      .authenticate({ oauth_token, session_duration_minutes: 60 })
      .finally(async () => { await navigate('/'); })
  }

  useEffect(() => {
    authenticate(token!)
  }, [])

  return <Spinner />
}
