import { useNotifications } from '../useNotifications'
import { useResetPatientProfileDataPointDefinitionsMutation } from './types'

interface Hook {
  resetPathways: (pathway_id?: string) => Promise<void>
}

export const useResetPatientProfileDataPointDefinitions = (): Hook => {
  const [
    resetPatientProfileDataPointDefinitions,
  ] = useResetPatientProfileDataPointDefinitionsMutation()
  const { notifyError, notify } = useNotifications()

  const resetPathways = async (pathway_id?: string) => {
    try {
      await resetPatientProfileDataPointDefinitions({
        variables: {
          input: {
            pathway_id,
          },
        },
      })
      notify('All patient profile data point definitions reset !')
    } catch (error) {
      notifyError({
        message:
          'Something went wrong while resetting the patient profile data point definitions',
        error,
      })
    }
  }

  return {
    resetPathways,
  }
}
