import React, { FC } from 'react'
import { LinearProgress } from '@awell/ui-kit/components/LinearProgress'
import { useStyles } from './useStyles'

interface StatusProps {
  eventCount: number
  replayedCount: number
  lastEventId: string
}

export const Status: FC<StatusProps> = ({
  eventCount,
  replayedCount,
  lastEventId,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.label}>{`${replayedCount} / ${eventCount}`}</div>
      <LinearProgress value={(replayedCount / eventCount) * 100} />
      <div>Last event id: {lastEventId}</div>
    </div>
  )
}

Status.displayName = 'RebuildViewModel.Status'
