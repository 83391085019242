import React, { useState, type FC } from 'react'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import { Button } from '@awell/ui-kit/components/Button'
import { useImportPathway } from '../../hooks/useImportPathway'
import { TenantSelect } from '../TenantSelect'
import { useStyles } from './useStyles'
import { useNotifications } from '../../hooks/useNotifications'
import { Status } from './Status'
import { isEmpty, isNil } from 'lodash'

export const ImportPathway: FC = () => {
  const { notifyError } = useNotifications()
  const { importPathway, status } = useImportPathway()
  const [tenantId, setTenantId] = useState<string>('')
  const [base64Json, setBase64Json] = useState<string>('')
  const classes = useStyles()

  const convertToBase64 = async (fileToUpload: File): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(fileToUpload)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const handleFileRead = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (isNil(event.target?.files)) {
      return
    }

    const fileToUpload = event.target.files[0]
    try {
      const base64 = await convertToBase64(fileToUpload)
      setBase64Json(base64)
    } catch (e) {
      notifyError({ message: 'Unable to convert file', error: e })
    }
  }

  const handleImport = (): void => {
    try {
      void importPathway({ tenant_id: tenantId, base64Json })
    } catch (error) {
      notifyError({
        error,
        message: 'Something went wrong while importing pathway',
      })
    }
  }

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Import pathway</h3>
      </PanelSectionHeader>
      <div className={classes.input}>
        <TenantSelect value={tenantId} onChange={setTenantId} />
      </div>
      <div className={classes.input}>
        <label>
          JSON File
          <input
            className={classes.fileInput}
            accept='.json'
            type='file'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async e => {
              await handleFileRead(e)
            }}
          />
        </label>
      </div>
      <Button
        variant='outlined'
        onClick={handleImport}
        disabled={isEmpty(tenantId) || isEmpty(base64Json)}
        className={classes.input}
      >
        Import
      </Button>
      {!isNil(status) && <Status status={status} />}
    </PanelSection>
  )
}
