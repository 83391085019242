// @ts-nocheck TODO: remove this comment and fix errors
import React, { useState, FC } from 'react'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import { InputField } from '@awell/ui-kit/components/InputField'
import { Button } from '@awell/ui-kit/components/Button'
import { PathwaySelect } from '../PathwaySelect'
import { useStyles } from './useStyles'

interface TransferPathwayProps {
  onTransferPathway: ({ pathwayId, userId }) => void
}

export const TransferPathway: FC<TransferPathwayProps> = ({
  onTransferPathway,
}) => {
  const [userId, setUserId] = useState('')
  const [pathwayId, setPathwayId] = useState('')
  const classes = useStyles()

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Transfer pathway</h3>
      </PanelSectionHeader>
      <div className={classes.input}>
        <PathwaySelect
          value={pathwayId}
          onChange={setPathwayId}
          multiple={false}
        />
      </div>
      <div className={classes.input}>
        <InputField
          type='text'
          label='User ID'
          value={userId}
          onChange={setUserId}
        />
      </div>
      <Button
        variant='outlined'
        onClick={() => onTransferPathway({ pathwayId, userId })}
        disabled={!pathwayId || !userId}
      >
        Transfer pathway
      </Button>
    </PanelSection>
  )
}

TransferPathway.displayName = 'TransferPathway'
