import React, { FC, useState } from 'react'
import { List, ListItem } from '@awell/ui-kit/components/List'
import { Button } from '@awell/ui-kit/components/Button'
import { InputField } from '@awell/ui-kit/components/InputField'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { DomainEventType } from './types'
import { EventListItem } from '../EventListItem'
import { useStyles } from './useStyles'

interface EventListProps {
  events: Array<DomainEventType>
  onSearch: ({
    stream,
    userId,
    sagaId,
    eventType,
  }: {
    stream?: string
    userId?: string
    sagaId?: string
    eventType?: string
  }) => void
}

export const EventList: FC<EventListProps> = ({ events, onSearch }) => {
  const classes = useStyles()
  const [stream, setStream] = useState('')
  const [userId, setUserId] = useState('')
  const [sagaId, setSagaId] = useState('')
  const [eventType, setEventType] = useState('')

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault()
    onSearch({ stream, userId, sagaId, eventType })
  }

  return (
    <div className={classes.container}>
      <form className={classes.searchBar} onSubmit={handleSearch}>
        <InputField
          label='Stream'
          value={stream}
          onChange={setStream}
          className={classes.searchField}
          compact
        />
        <InputField
          label='Event type'
          value={eventType}
          onChange={setEventType}
          className={classes.searchField}
          compact
        />
        <InputField
          label='User ID'
          value={userId}
          onChange={setUserId}
          className={classes.searchField}
          compact
        />
        <InputField
          label='Saga ID'
          value={sagaId}
          onChange={setSagaId}
          className={classes.searchField}
          compact
        />
        <div className={classes.searchButton}>
          <Button variant='text' onClick={handleSearch}>
            Search
          </Button>
        </div>
      </form>
      <ScrollContainer>
        <List divided>
          {events.map(event => (
            <ListItem key={event.id}>
              <EventListItem event={event} />
            </ListItem>
          ))}
        </List>
      </ScrollContainer>
    </div>
  )
}
