import React, { FC } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { useAddCustomActionToPathwayMutation, CustomActionInput } from './types'
import { AddCustomActionToPathway } from './AddCustomActionToPathway'

export const AddCustomActionToPathwayContainer: FC = () => {
  const { notifyError, notify } = useNotifications()
  const [addCustomActionToPathway] = useAddCustomActionToPathwayMutation()

  const onAddCustomActionToPathways = async ({
    pathwayIds,
    customAction,
  }: {
    pathwayIds: Array<string>
    customAction: CustomActionInput
  }) => {
    try {
      await Promise.all(
        pathwayIds.map(async pathway_id => {
          await addCustomActionToPathway({
            variables: {
              input: {
                pathway_id,
                custom_action: customAction,
              },
            },
          })
        }),
      )
      notify('Successfully added custom action to pathway(s)')
    } catch (error) {
      notifyError({
        error,
        message:
          'Something went wrong while adding custom action to pathway(s)',
      })
    }
  }

  return (
    <AddCustomActionToPathway
      onAddCustomActionToPathway={onAddCustomActionToPathways}
    />
  )
}
