// @ts-nocheck TODO: remove this comment and fix errors
import { useState } from 'react'
import { useForms } from '../useForms'
import { useNotifications } from '../useNotifications'
import { usePathways } from '../usePathways'
import { useRebuildViewModels } from '../useRebuildViewModels'
import { useShareForms } from '../useShareForms'
import { useSharePathways } from '../useSharePathways'

export enum ImportState {
  'IDLE',
  'REBUILDING_VIEW_MODELS',
  'SHARING_PATHWAYS_AND_FORMS',
}

export const useImportEventStream = () => {
  const { notifyError } = useNotifications()
  const { rebuildViewModels } = useRebuildViewModels()
  const { pathways } = usePathways()
  const { forms } = useForms()
  const { sharePathways } = useSharePathways()
  const { shareForms } = useShareForms()
  const [importState, setImportState] = useState<ImportState>(ImportState.IDLE)

  const triggerViewModelRebuild = async () => {
    setImportState(ImportState.REBUILDING_VIEW_MODELS)
    await rebuildViewModels()
  }

  const shareEverything = async ({
    pathwayIds,
  }: {
    pathwayIds?: Array<string>
  }) => {
    try {
      setImportState(ImportState.SHARING_PATHWAYS_AND_FORMS)
      if (!team) {
        return
      }
      const team_id = team.id
      if (pathways) {
        if (pathwayIds) {
          await sharePathways(team_id, pathwayIds)
        } else {
          const pathway_ids = pathways.map(pathway => pathway.id)
          await sharePathways(team_id, pathway_ids)
        }
      }
      if (forms) {
        const form_ids = forms.map(form => form.id)
        await shareForms(team_id, form_ids)
      }
    } catch (error) {
      notifyError({
        message: 'Something went wrong while sharing the pathways and forms',
        error,
      })
    }
  }

  return {
    importState,
    rebuildViewModels: triggerViewModelRebuild,
    shareEverything,
  }
}
