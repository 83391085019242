import { useNotifications } from '../useNotifications'
import { useRecomputeCalculationDataSourcesMutation } from './types'

interface Hook {
  recompute: () => Promise<void>
}

export const useRecomputeCalculationDataSources = (): Hook => {
  const [
    recomputeCalculationDataSources,
  ] = useRecomputeCalculationDataSourcesMutation()
  const { notifyError, notify } = useNotifications()

  const recompute = async () => {
    try {
      await recomputeCalculationDataSources({})
      notify('All calculation data sources recomputed !')
    } catch (error) {
      notifyError({
        message:
          'Something went wrong while recomputing the calculation data sources',
        error,
      })
    }
  }

  return {
    recompute,
  }
}
