import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'

const useStyles = makeStyles({
  panelTop: {
    ...spacing.padding.xxs,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.neutralLight20,
  },
})

export const PanelTop: FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.panelTop}>{children}</div>
}
PanelTop.displayName = 'PanelTop'
