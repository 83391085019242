// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import { InputField } from '@awell/ui-kit/components/InputField'
import {
  PanelSection,
  PanelSectionHeader,
} from '@awell/ui-kit/components/PanelSection'
import React, { FC, useState } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { PathwaySelect } from '../PathwaySelect'
import { Label } from './types'
import { useStyles } from './useStyles'

interface AddCustomFieldToPathwayProps {
  onAddCustomFieldToPathways: ({
    pathwayIds,
    labels,
  }: {
    pathwayIds: Array<string>
    labels: Array<Label>
  }) => void
}

export const AddCustomFieldToPathway: FC<AddCustomFieldToPathwayProps> = ({
  onAddCustomFieldToPathways,
}) => {
  const { notifyError } = useNotifications()
  const [pathwayIds, setPathwayIds] = useState([])
  const [labelsString, setLabelString] = useState('')
  const classes = useStyles()

  const onClickAddCustomFieldToAllPathways = (labelsString: string) => {
    try {
      onAddCustomFieldToPathways({
        pathwayIds,
        labels: JSON.parse(labelsString),
      })
    } catch (error) {
      notifyError({
        error,
        message:
          'Something went wrong while parsing the input string, please check the input',
      })
    }
  }

  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Add Custom Field to pathway(s)</h3>
      </PanelSectionHeader>
      <div className={classes.input}>
        <PathwaySelect value={pathwayIds} onChange={setPathwayIds} multiple />
      </div>
      <div>
        This maintenance operation does the following:
        <ul>
          <li>Adds the supplied labels to all the pathways</li>
          <li>All the previous labels are removed</li>
          <li>Please input the labels array in the input field</li>
          <li>
            For e.g.{' '}
            {'[{"color": "blue", "text": ""}, {"color": "green", "text": ""}]'}
          </li>
        </ul>
      </div>
      <div className={classes.input}>
        <InputField
          type='text'
          label='Labels'
          value={labelsString}
          onChange={setLabelString}
        />
      </div>
      <Button
        onClick={() => onClickAddCustomFieldToAllPathways(labelsString)}
        disabled={!labelsString}
      >
        Add Custom Field To Pathways
      </Button>
    </PanelSection>
  )
}
