import { makeStyles } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  container: {
    ...spacing.margin.m,
    height: '100%',
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: spacing.m,
  },
  searchField: {
    flexGrow: 1,
    marginRight: spacing.m,
  },
  searchButton: {
    marginRight: spacing.m,
    '&:last-child': {
      marginRight: 0,
    },
  },
})
