// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { useTransferPathwayMutation } from './types'
import { TransferPathway } from './TransferPathway'

export const TransferPathwayContainer: FC = () => {
  const { notifyError, notify } = useNotifications()
  const [transferPathway] = useTransferPathwayMutation()

  const onTransferPathway = async ({ pathwayId, userId }) => {
    try {
      await transferPathway({
        variables: {
          input: {
            pathway_id: pathwayId,
            new_user_id: userId,
          },
        },
      })
      notify('Transfer completed')
    } catch (error) {
      notifyError({
        error,
        message: 'Something went wrong while transferring a pathway',
      })
    }
  }

  return <TransferPathway onTransferPathway={onTransferPathway} />
}

TransferPathwayContainer.displayName = 'TransferPathwayContainer'
