import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { SnackbarProvider } from 'notistack'

const useStyles = makeStyles({
  error: {
    backgroundColor: colors.signalError100,
  },
})

export const NotificationProvider: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      classes={{ variantError: classes.error }}
      maxSnack={3}
      hideIconVariant
      autoHideDuration={5000}
    >
      {children}
    </SnackbarProvider>
  )
}
