// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useState } from 'react'
import {
  PanelSectionHeader,
  PanelSection,
} from '@awell/ui-kit/components/PanelSection'
import { Button } from '@awell/ui-kit/components/Button'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { PathwaySelect } from '../PathwaySelect'
import { useResetPatientProfileDataPointDefinitions } from '../../hooks/useResetPatientProfileDataPointDefinitions'

export const ResetPatientProfileDataPointDefinitions: FC = () => {
  const [pathwayId, setPathwayId] = useState(undefined)
  const { resetPathways } = useResetPatientProfileDataPointDefinitions()
  return (
    <PanelSection paddingTop paddingBottom>
      <PanelSectionHeader>
        <h3>Reset patient profile data point definitions</h3>
      </PanelSectionHeader>
      <div>
        This maintenance operation does the following:
        <ul>
          <li>
            Reset patient profile data point definitions (currently adds patient
            first name and last name only)
          </li>
        </ul>
        Pathway is optional, if you don&apos;t select a specific pathway all
        pathways will be reset
      </div>
      <div style={{ marginBottom: spacing.xs }}>
        <PathwaySelect
          value={pathwayId}
          onChange={setPathwayId}
          multiple={false}
        />
      </div>
      <Button onClick={() => resetPathways(pathwayId)}>
        Reset patient profile data point definitions
      </Button>
    </PanelSection>
  )
}

ResetPatientProfileDataPointDefinitions.displayName =
  'ResetPatientProfileDataPointDefinitions'
