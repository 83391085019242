import * as React from 'react'

function SvgExpand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.121 7.293a1 1 0 00-.083 1.32l.083.094 2.586 2.586a1 1 0 01.083 1.32l-.083.094-2.586 2.586a1 1 0 001.32 1.497l.095-.083 2.585-2.586a2.999 2.999 0 00.135-4.098l-.135-.144-2.585-2.586a1.002 1.002 0 00-1.415 0zm-8 0a1 1 0 00-.083 1.32l.083.094 2.586 2.586a1 1 0 01.083 1.32l-.083.094-2.586 2.586a1 1 0 001.32 1.497l.095-.083 2.585-2.586a2.999 2.999 0 00.135-4.098l-.135-.144-2.585-2.586a1.002 1.002 0 00-1.415 0z'
      />
    </svg>
  )
}

export default SvgExpand
