import React, { FC, useState } from 'react'
import { IconButton } from '@awell/ui-kit/components/Button'
import { Icon, IconNames } from '@awell/ui-kit/components/Icon'
import { Collapse, Expand } from '@awell/ui-kit/components/Icons'
import { DomainEventType } from './types'
import { useStyles } from './useStyles'

interface EventListItemProps {
  event: DomainEventType
}

export const EventListItem: FC<EventListItemProps> = ({ event }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  return (
    <div className={classes.container}>
      <div className={classes.summary}>
        <div>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <Collapse /> : <Expand />}
          </IconButton>
        </div>
        <div className={classes.info}>
          <div className={classes.type}>{event.event_type}</div>
          <div className={classes.type}>{event.stream}</div>
          <div className={classes.timestamp}>
            <Icon name={IconNames.calendar} />
            {event.metadata.timestamp}
          </div>
          <div className={classes.user}>
            <Icon name={IconNames.user} />
            {event.metadata.user_id}
          </div>
        </div>
        <div className={classes.id}>
          ID: <span className={classes.code}>{event.id}</span>
        </div>
      </div>
      {expanded && (
        <div className={classes.data}>
          {event.data && (
            <pre>{JSON.stringify(JSON.parse(event.data), null, 2)}</pre>
          )}
        </div>
      )}
      <div className={classes.metadata}>
        <div>
          Version:{' '}
          <span className={classes.code}>{event.metadata.version}</span>
        </div>
        <div>
          Saga: <span className={classes.code}>{event.metadata.saga_id}</span>
        </div>
        {event.metadata.caused_by && (
          <div>
            Caused by:{' '}
            <span className={classes.code}>{event.metadata.caused_by}</span>
          </div>
        )}
      </div>
    </div>
  )
}

EventListItem.displayName = 'EventListItem'
